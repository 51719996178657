<template>
  <div class="topic_info">
    <div class="title">
      <span style="padding-right:10px">
        <!-- {{topic}} -->
        <!-- 题目ID：{{rands(topic.question_id)}} -->
        <span style="padding-right:10rem"> 题目ID：{{topic.question_id}}</span>
        <span style="color:#666">({{topic.question_source_year}}，{{topic.question_type_name}})</span>

      </span>
      <span style="display: inline-block">
        题目分数：{{topic.question_score}}
      </span>

    </div>
    <div class="list">

      <div class="item">
        <!-- accordion -->
        <div class="content"
             v-html="topic.question_content"></div>
        <a-collapse v-model:activeKey="activeKey"
                    expandIconPosition="right">

          <a-collapse-panel key="1"
                            header="题目解析">
            <div class="content"
                 v-html="topic.question_explain"></div>
          </a-collapse-panel>
          <a-collapse-panel key="2"
                            header="题目答案">
            <div class="content"
                 v-html="topic.question_answer"></div>
          </a-collapse-panel>

        </a-collapse>
      </div>
    </div>

  </div>
</template>

<script setup>
import { rands } from '@/utils/rand.js'
import { ref, toRefs } from '@vue/reactivity'
import { nextTick } from 'vue'
import { message } from 'ant-design-vue'

const pData = defineProps({
  topic: {
    type: Object,
    default: () => {
      return {
        question_id: '',
        question_score: '',
        user_score: '',
      }
    }
  }
})
let { topic } = toRefs(pData)

let activeKey = ref([])

nextTick(() => {
  setTimeout(() => {
    let topicContentTd = document.getElementsByTagName('td')
    if (topicContentTd && topicContentTd.length) {
      for (let i = 0; i < topicContentTd.length; i++) {
        if (topicContentTd[i].width) {
          topicContentTd[i].width = topicContentTd[i].width * 18 / 16
        }
      }
    }
  })
})
// 5442



</script>

<style lang="scss" scoped>
.topic_info {
  box-shadow: 1px 2px 4px 0px #ddd;
  margin: 0 0 20rem;
  padding: 10rem;
  background-color: white;
  .title {
    padding: 16rem 30rem;
    background: #eff8ff;
    font-size: 20rem;
    font-weight: bold;
  }
  .list {
    margin-top: 0;
    font-size: 20rem;
    font-weight: 500;
    .item {
      color: #333333;
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60rem;
        background: #f6f6f6;
        border-radius: 10rem;
        div {
          &:nth-child(1) {
            text-indent: 20rem;
          }
          &:nth-child(2) {
            margin-right: 30rem;
          }
        }
      }
    }
    .content {
      font-size: 20rem;
      margin: 20rem;
    }
  }
}
:deep .ant-collapse {
  border: none;
  background-color: #ffffff !important;
  font-size: 20rem;
}
:deep .ant-collapse-item {
  overflow: hidden;
}

:deep .ant-collapse-header {
  background: #f6f6f6;
  border-radius: 10rem;
}
:deep .ant-collapse > .ant-collapse-item {
  border: none;
  margin-top: 20rem;
}
:deep .ant-collapse-content {
  border-top: none;
}
:deep
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 20rem;
}
</style>