<template>
  <div class="topic_bg">
    <TopCard ref="TopCardRef"
             text="题目详情">
    </TopCard>
    <div class="topic_main_wrap">
      <!-- 大题的情况下 -->
      <div v-if='topic.length>0'>
        <div class="warp"
             v-if="topic[0].question_head_content">
          <!-- 左侧题目内容 -->
          <div class="left_topic">
            <div class="title">
              题目{{topic[0].answer_id}}({{topic[0].question_source_year}}，{{topic[0].question_type_name}})
              <div>
                <!-- ID:{{rands(topic.question_id)}} -->
                ID:{{route.query.topicId}}
              </div>
            </div>
            <div v-html="topic[0].question_head_content"
                 class="content">
            </div>
          </div>
          <!-- 右侧信息区域 -->
          <div class="right_topic_info">
            <div v-for="(item,index) in topic"
                 :key="index">

              <TopicItem :topic="item" />
            </div>
          </div>
        </div>

        <div class="xiao_warp"
             v-else>
          <div class="box">

            <TopicItem :topic="topic[0]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TopicItem from './components/topicItem.vue'
import { onMounted, ref, computed } from 'vue'
import { rands as utilsRand } from '@/utils/rand.js'
import { useRoute } from 'vue-router'
import { getTopic } from '@/api/topic.js'
import TopCard from '@/views/components/top_card/index.vue'
let topic = ref({})
let route = useRoute()
onMounted(async () => {
  const { data } = await getTopic({
    question_id: route.query.topicId
  })
  topic.value = data.list
})
let score = computed(() => {
  let score = 0
  if (topic.value.length > 1) {
    topic.value.forEach(item => {
      score += Number(item.question_score)
    })
    return score
  }
})


const rands = utilsRand

</script>

<style lang="scss" scoped>
.warp {
  display: flex;
  margin: 20rem 0 20rem 20rem;
  .left_topic {
    flex: 1;
    background: white;
    padding: 10px;
    margin-right: 20rem;
    height: calc(100vh - 150rem);
    overflow: auto;
  }
  .right_topic_info {
    // background: #f6f6f6;
    overflow: auto;
    height: calc(100vh - 150rem);
    flex: 1;
    margin-right: 20rem;
  }
}
.xiao_warp {
  // width: calc(100vw - 140rem);
  background: white;
  overflow: auto;
  padding: 10px;
  margin: 0 20rem 20rem 20rem;
}
.content {
  overflow: auto;
  font-size: 20rem;
}
.box {
  overflow-y: scroll;
}
.title {
  font-size: 20rem;
  margin-bottom: 20rem;
  font-weight: 500;
  color: #666666;
  // line-height: 28rem;
  position: relative;
}
.question_id {
  position: absolute;
  right: 0;
  top: 0;
}
.topic_bg {
  background: #f6f6f6;
}
.topic_main_wrap {
  height: calc(100vh - 100rem);
  overflow: auto;
}
</style>